import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { FormBox, FormFlex, FormGroupAdapter, FormLegend } from 'components/shared/form';

import { SIGNATURE_INITIAL_VALUES } from 'constants/forms';
import { APP_NAME } from 'constants/views';
import { validateAccepted } from 'utils';

import { LegendFormFlex, StyledTextarea } from '.';

const propTypes = {
  disabled: PropTypes.bool,
  signatureVersion: PropTypes.string,
};

const defaultProps = {
  disabled: true,
  signatureVersion: 'v1',
};

function AffiliateBusiness({ disabled, signatureVersion }) {
  const shouldShow = ['v5'].includes(signatureVersion) && APP_NAME === 'LendingOne';
  if (!shouldShow) return null;

  return (
    <Fragment>
      <LegendFormFlex justifyContent="space-between">
        <FormBox>
          <FormLegend>Affiliate Business Relationship Disclosure:</FormLegend>
        </FormBox>
      </LegendFormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea
            rows={8}
            disabled
            value={SIGNATURE_INITIAL_VALUES[signatureVersion].affiliateBusiness}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.affiliateBusiness"
            type="checkbox"
            label="I have Reviewed, Acknowledged and Agree."
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            disabled={disabled}
            required
            validate={validateAccepted()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

AffiliateBusiness.propTypes = propTypes;
AffiliateBusiness.defaultProps = defaultProps;

export default AffiliateBusiness;
